import React from 'react';
import './BirthDate.css';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { storeSlice } from '../../store/reducers/StoreSlice';

export const BirthDate = () => {
  const { userDataPersonal } = useAppSelector((state) => state.storeReducer);
  const dispatch = useAppDispatch();

  const date = new Date().getFullYear();

  return (
    <div className="birth-data">
      <button
        className="btn"
        onClick={() => {
          dispatch(storeSlice.actions.resetUserData());
        }}
      >
        Ввести другую дату
      </button>
      <div>
        <b>Дата рождения: </b>
        {userDataPersonal.birth}
      </div>
      <div>
        <b>{`Имя и возраст: `}</b>
        {userDataPersonal.name}, {userDataPersonal.yearsOld}
      </div>
    </div>
  );
};
