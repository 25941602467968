import React from 'react';
import { useNavigate } from 'react-router-dom';
import InstImg from '../../../assets/inst.png';
import TikTokImg from '../../../assets/tiktok.png';
import TelegramImg from '../../../assets/tg.png';
import YouTube from '../../../assets/you.png';

import './MatrixSelectionPage.scss';
import { storeSlice } from '../../../store/reducers/StoreSlice';
import { useAppDispatch } from '../../../hooks/redux';

export const MatrixSelectionPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className="matrix-selection-page_wrapper">
      <h3 className="matrix-selection-page_wrapper_h3">БЕСПЛАТНЫЙ КАЛЬКУЛЯТОР</h3>
      <h1 className="matrix-selection-page_wrapper_h1">МАТРИЦА СУДЬБЫ</h1>
      <div className="buttons_wrapper">
        <button
          className="btn buttons_wrapper-btn"
          onClick={async () => {
            dispatch(storeSlice.actions.setClearState());
            navigate('/personal-matrix');
          }}
        >
          ЛИЧНАЯ МАТРИЦА
        </button>
        <button
          className="btn buttons_wrapper-btn"
          onClick={async () => {
            dispatch(storeSlice.actions.setClearState());
            navigate('/compatibility-matrix');
          }}
        >
          МАТРИЦА СОВМЕСТИМОСТИ
        </button>
      </div>
      <div className="social-links_wrapper">
        <div className="social-link">
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/_risharik.com_2023/">
            <img src={InstImg} alt="InstImg" width="50px" />
          </a>
        </div>
        <div className="social-link">
          <a target="_blank" rel="noreferrer" href="https://t.me/risharik_transformation">
            <img src={TelegramImg} alt="TelegramImg" width="50px" />
          </a>
        </div>
        <div className="social-link">
          <a target="_blank" rel="noreferrer" href="https://www.youtube.com/@Risharik">
            <img src={YouTube} alt="TikTokImg" height="45px" />
          </a>
        </div>
        <div className="social-link">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.tiktok.com/@risharik0?_t=8g0L8nnlIeM"
          >
            <img src={TikTokImg} alt="TikTokImg" width="45px" />
          </a>
        </div>
      </div>
    </div>
  );
};
