import React from 'react';
import './AppointmentCompatibility.scss';
import { getSum } from '../../functions/calculator';
import { IUserData } from '../../types';

interface IMatrixCompatibilityProps {
  mainString1: string[];
  mainString2: string[];
  userData1: IUserData;
  userData2: IUserData;
  getCirclesValue: (mainString: string[], birth: string) => { [key: string]: number };
}

export const AppointmentCompatibility = ({
  mainString1,
  mainString2,
  userData1,
  userData2,
  getCirclesValue,
}: IMatrixCompatibilityProps) => {
  return (
    <div className="appointment-compatibility">
      <h3>КАРТА ЖИЗНИ</h3>
      <div className="life-map_wrapper">
        <div className="life-map_item">
          <div className="life-map_item-circle">
            {getSum(
              getSum(
                getCirclesValue(mainString1, userData1.birth).circleLarge1 +
                  getCirclesValue(mainString2, userData2.birth).circleLarge1
              ) +
                getSum(
                  getCirclesValue(mainString1, userData1.birth).circleLarge8 +
                    getCirclesValue(mainString2, userData2.birth).circleLarge8
                )
            )}
          </div>
          <div className="life-map_item-title">НЕБЕСНОЕ ПРЕДНАЗНАЧЕНИЕ</div>
        </div>
        <div className="life-map_item">
          <div className="life-map_item-circle">
            {getSum(
              getSum(
                getCirclesValue(mainString1, userData1.birth).circleLarge4 +
                  getCirclesValue(mainString2, userData2.birth).circleLarge4
              ) +
                getSum(
                  getCirclesValue(mainString1, userData1.birth).circleLarge5 +
                    getCirclesValue(mainString2, userData2.birth).circleLarge5
                )
            )}
          </div>
          <div className="life-map_item-title">ЗЕМНОЕ ПРЕДНАЗНАЧЕНИЕ</div>
        </div>
        <div className="life-map_item">
          <div className="life-map_item-circle circle-bg">
            {getSum(
              getSum(
                getSum(
                  getCirclesValue(mainString1, userData1.birth).circleLarge1 +
                    getCirclesValue(mainString2, userData2.birth).circleLarge1
                ) +
                  getSum(
                    getCirclesValue(mainString1, userData1.birth).circleLarge8 +
                      getCirclesValue(mainString2, userData2.birth).circleLarge8
                  )
              ) +
                getSum(
                  getSum(
                    getCirclesValue(mainString1, userData1.birth).circleLarge4 +
                      getCirclesValue(mainString2, userData2.birth).circleLarge4
                  ) +
                    getSum(
                      getCirclesValue(mainString1, userData1.birth).circleLarge5 +
                        getCirclesValue(mainString2, userData2.birth).circleLarge5
                    )
                )
            )}
          </div>
          <div className="life-map_item-title">ЛИЧНОЕ ПРЕДНАЗНАЧЕНИЕ</div>
        </div>
      </div>
      <div className="life-map_wrapper">
        <div className="life-map_item">
          <div className="life-map_item-circle">
            {getSum(
              getSum(
                getCirclesValue(mainString1, userData1.birth).circleLarge2 +
                  getCirclesValue(mainString2, userData2.birth).circleLarge2
              ) +
                getSum(
                  getCirclesValue(mainString1, userData1.birth).circleLarge7 +
                    getCirclesValue(mainString2, userData2.birth).circleLarge7
                )
            )}
          </div>
          <div className="life-map_item-title">МУЖСКОЕ ПРЕДНАЗНАЧЕНИЕ</div>
        </div>
        <div className="life-map_item">
          <div className="life-map_item-circle">
            {getSum(
              getSum(
                getCirclesValue(mainString1, userData1.birth).circleLarge3 +
                  getCirclesValue(mainString2, userData2.birth).circleLarge3
              ) +
                getSum(
                  getCirclesValue(mainString1, userData1.birth).circleLarge6 +
                    getCirclesValue(mainString2, userData2.birth).circleLarge6
                )
            )}
          </div>
          <div className="life-map_item-title">ЖЕНСКОЕ ПРЕДНАЗНАЧЕНИЕ</div>
        </div>
        <div className="life-map_item">
          <div className="life-map_item-circle circle-bg">
            {getSum(
              getSum(
                getSum(
                  getCirclesValue(mainString1, userData1.birth).circleLarge2 +
                    getCirclesValue(mainString2, userData2.birth).circleLarge2
                ) +
                  getSum(
                    getCirclesValue(mainString1, userData1.birth).circleLarge7 +
                      getCirclesValue(mainString2, userData2.birth).circleLarge7
                  )
              ) +
                getSum(
                  getSum(
                    getCirclesValue(mainString1, userData1.birth).circleLarge3 +
                      getCirclesValue(mainString2, userData2.birth).circleLarge3
                  ) +
                    getSum(
                      getCirclesValue(mainString1, userData1.birth).circleLarge6 +
                        getCirclesValue(mainString2, userData2.birth).circleLarge6
                    )
                )
            )}
          </div>
          <div className="life-map_item-title">СОЦИАЛЬНОЕ ПРЕДНАЗНАЧЕНИЕ</div>
        </div>
      </div>
      <div className="life-map_wrapper">
        <div className="life-map_item">
          <div className="life-map_item-circle">
            {getSum(
              getSum(
                getSum(
                  getCirclesValue(mainString1, userData1.birth).circleLarge1 +
                    getCirclesValue(mainString2, userData2.birth).circleLarge1
                ) +
                  getSum(
                    getCirclesValue(mainString1, userData1.birth).circleLarge8 +
                      getCirclesValue(mainString2, userData2.birth).circleLarge8
                  )
              ) +
                getSum(
                  getSum(
                    getCirclesValue(mainString1, userData1.birth).circleLarge4 +
                      getCirclesValue(mainString2, userData2.birth).circleLarge4
                  ) +
                    getSum(
                      getCirclesValue(mainString1, userData1.birth).circleLarge5 +
                        getCirclesValue(mainString2, userData2.birth).circleLarge5
                    )
                )
            )}
          </div>
          <div className="life-map_item-title">ЛИЧНОЕ ПРЕДНАЗНАЧЕНИЕ</div>
        </div>
        <div className="life-map_item">
          <div className="life-map_item-circle">
            {getSum(
              getSum(
                getSum(
                  getCirclesValue(mainString1, userData1.birth).circleLarge2 +
                    getCirclesValue(mainString2, userData2.birth).circleLarge2
                ) +
                  getSum(
                    getCirclesValue(mainString1, userData1.birth).circleLarge7 +
                      getCirclesValue(mainString2, userData2.birth).circleLarge7
                  )
              ) +
                getSum(
                  getSum(
                    getCirclesValue(mainString1, userData1.birth).circleLarge3 +
                      getCirclesValue(mainString2, userData2.birth).circleLarge3
                  ) +
                    getSum(
                      getCirclesValue(mainString1, userData1.birth).circleLarge6 +
                        getCirclesValue(mainString2, userData2.birth).circleLarge6
                    )
                )
            )}
          </div>
          <div className="life-map_item-title">СОЦИАЛЬНЕ ПРЕДНАЗНАЧЕНИЕ</div>
        </div>
        <div className="life-map_item">
          <div className="life-map_item-circle circle-bg-dark">
            {getSum(
              getSum(
                getSum(
                  getSum(
                    getCirclesValue(mainString1, userData1.birth).circleLarge2 +
                      getCirclesValue(mainString2, userData2.birth).circleLarge2
                  ) +
                    getSum(
                      getCirclesValue(mainString1, userData1.birth).circleLarge7 +
                        getCirclesValue(mainString2, userData2.birth).circleLarge7
                    )
                ) +
                  getSum(
                    getSum(
                      getCirclesValue(mainString1, userData1.birth).circleLarge3 +
                        getCirclesValue(mainString2, userData2.birth).circleLarge3
                    ) +
                      getSum(
                        getCirclesValue(mainString1, userData1.birth).circleLarge6 +
                          getCirclesValue(mainString2, userData2.birth).circleLarge6
                      )
                  )
              ) +
                getSum(
                  getSum(
                    getSum(
                      getCirclesValue(mainString1, userData1.birth).circleLarge1 +
                        getCirclesValue(mainString2, userData2.birth).circleLarge1
                    ) +
                      getSum(
                        getCirclesValue(mainString1, userData1.birth).circleLarge8 +
                          getCirclesValue(mainString2, userData2.birth).circleLarge8
                      )
                  ) +
                    getSum(
                      getSum(
                        getCirclesValue(mainString1, userData1.birth).circleLarge4 +
                          getCirclesValue(mainString2, userData2.birth).circleLarge4
                      ) +
                        getSum(
                          getCirclesValue(mainString1, userData1.birth).circleLarge5 +
                            getCirclesValue(mainString2, userData2.birth).circleLarge5
                        )
                    )
                )
            )}
          </div>
          <div className="life-map_item-title">ДУХОВНОЕ ПРЕДНАЗНАЧЕНИЕ</div>
        </div>
      </div>
    </div>
  );
};
