import React, { useEffect, useState } from 'react';
import './Purposes.css';
import {
  getSum,
  largeCircleValue1,
  largeCircleValue2,
  largeCircleValue3,
  largeCircleValue4,
  largeCircleValue5,
  largeCircleValue6,
  largeCircleValue7,
  largeCircleValue8,
  largeCircleValueCenter,
} from '../../functions/calculator';
import { useAppSelector } from '../../hooks/redux';

interface IYearsTableProps {
  getEnergyValue: () => { [key: string]: number };
}

export const Purposes = ({ getEnergyValue }: IYearsTableProps) => {
  const { mainString, userDataPersonal } = useAppSelector((state) => state.storeReducer);

  const [firstCircle, setFirstCircle] = useState<string>('');
  const [secondCircle, setSecondCircle] = useState<string>('');
  const [thirdCircle, setThirdCircle] = useState<string>('');

  function getYearId() {
    const yearIDPlus = parseInt(userDataPersonal.yearsOld) + 40;
    if (yearIDPlus >= 80) {
      return yearIDPlus % 80;
    }
    return yearIDPlus;
  }

  useEffect(() => {
    if (document.getElementById(parseInt(userDataPersonal.yearsOld).toString())) {
      setFirstCircle(
        document.getElementById(parseInt(userDataPersonal.yearsOld).toString())!.innerHTML
      );
      console.log(getYearId());
      setSecondCircle(document.getElementById(getYearId().toString())!.innerHTML);
      setThirdCircle(
        getSum(
          parseInt(secondCircle) +
            parseInt(
              document.getElementById(parseInt(userDataPersonal.yearsOld).toString())!.innerHTML
            )
        ).toString()
      );
    } else {
      setFirstCircle('-');
      setSecondCircle('-');
      setThirdCircle('-');
    }
  }, [secondCircle]);

  return (
    <div className="purposes_container">
      <h3>ПРЕДНАЗНАЧЕНИЕ</h3>
      <div className="purposes">
        <div className="purposes_top">
          <div className="purpose personal">
            <h3 className="purpose_h3">Для себя</h3>
            <p>Наше задание, урок, который мы пришли получить для себя</p>
            <div className="rect">
              <div className="content">
                <div className="content_row">
                  Небо:
                  <div className="circle_purpose">
                    {getSum(
                      largeCircleValue1(mainString, userDataPersonal.birth) +
                        largeCircleValue8(mainString, userDataPersonal.birth)
                    )}
                  </div>
                </div>
                <div className="content_row">
                  Земля:
                  <div className="circle_purpose">
                    {getSum(
                      largeCircleValue4(mainString, userDataPersonal.birth) +
                        largeCircleValue5(mainString, userDataPersonal.birth)
                    )}
                  </div>
                </div>
              </div>
              <div className="right-border">
                <span className="span left-brace-tl"></span>
                <span className="span left-brace-tr"></span>
                <br />
                <span className="span left-brace-bl"></span>
                <span className="span left-brace-br"></span>
              </div>
              <div className="circle_purpose circle_sum">
                {getSum(
                  getSum(
                    largeCircleValue1(mainString, userDataPersonal.birth) +
                      largeCircleValue8(mainString, userDataPersonal.birth)
                  ) +
                    getSum(
                      largeCircleValue4(mainString, userDataPersonal.birth) +
                        largeCircleValue5(mainString, userDataPersonal.birth)
                    )
                )}
              </div>
            </div>
          </div>
          <div className="purpose social">
            <h3 className="purpose_h3">Для людей</h3>
            <p>
              Наша миссия в этой жизни для других, то, чему мы можем их научить, открыть для них,
              исполнение которой несет финансовые блага от мира.
            </p>
            <div className="rect">
              <div className="content">
                <div className="content_row word">
                  <span className="word">Ч:</span>
                  <div className="circle_purpose">
                    {getSum(
                      largeCircleValue2(mainString, userDataPersonal.birth) +
                        largeCircleValue7(mainString, userDataPersonal.birth)
                    )}
                  </div>
                </div>
                <div className="content_row word">
                  <span className="word">Ж:</span>
                  <div className="circle_purpose">
                    {getSum(
                      largeCircleValue6(mainString, userDataPersonal.birth) +
                        largeCircleValue3(mainString, userDataPersonal.birth)
                    )}
                  </div>
                </div>
              </div>
              <div className="right-border">
                <span className="span left-brace-tl"></span>
                <span className="span left-brace-tr"></span>
                <br />
                <span className="span left-brace-bl"></span>
                <span className="span left-brace-br"></span>
              </div>
              <div className="circle_purpose circle_sum">
                {getSum(
                  getSum(
                    largeCircleValue2(mainString, userDataPersonal.birth) +
                      largeCircleValue7(mainString, userDataPersonal.birth)
                  ) +
                    getSum(
                      largeCircleValue6(mainString, userDataPersonal.birth) +
                        largeCircleValue3(mainString, userDataPersonal.birth)
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="purpose purpose_bottom">
        <div className="purpose_general">
          <h3 className="purpose_h3">Духовное</h3>
          <p>
            То, что нужно вынести из всей нашей жизни как результат. Енергия в этой позиции влияет
            абсолютно на каждую енергию, которая стоит в нашей Матрице.
          </p>
          <div className="">
            <div className="content circle_solo">
              <div className="circle">
                {getSum(
                  getSum(
                    getSum(
                      largeCircleValue1(mainString, userDataPersonal.birth) +
                        largeCircleValue8(mainString, userDataPersonal.birth)
                    ) +
                      getSum(
                        largeCircleValue4(mainString, userDataPersonal.birth) +
                          largeCircleValue5(mainString, userDataPersonal.birth)
                      )
                  ) +
                    getSum(
                      getSum(
                        getSum(
                          largeCircleValue2(mainString, userDataPersonal.birth) +
                            largeCircleValue7(mainString, userDataPersonal.birth)
                        ) +
                          getSum(
                            largeCircleValue6(mainString, userDataPersonal.birth) +
                              largeCircleValue3(mainString, userDataPersonal.birth)
                          )
                      )
                    )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="purpose_planetary">
          <h3 className="purpose_h3">Планетарное</h3>
          <p>Наш вклад в человечество, то, как мы можем повлиять на мир.</p>
          <div className="rect">
            <div className="content circle_solo">
              <div className="circle">
                {getSum(
                  getSum(
                    getSum(
                      largeCircleValue2(mainString, userDataPersonal.birth) +
                        largeCircleValue7(mainString, userDataPersonal.birth)
                    ) +
                      getSum(
                        largeCircleValue6(mainString, userDataPersonal.birth) +
                          largeCircleValue3(mainString, userDataPersonal.birth)
                      )
                  ) +
                    getSum(
                      getSum(
                        getSum(
                          largeCircleValue1(mainString, userDataPersonal.birth) +
                            largeCircleValue8(mainString, userDataPersonal.birth)
                        ) +
                          getSum(
                            largeCircleValue4(mainString, userDataPersonal.birth) +
                              largeCircleValue5(mainString, userDataPersonal.birth)
                          )
                      ) +
                        getSum(
                          getSum(
                            getSum(
                              largeCircleValue2(mainString, userDataPersonal.birth) +
                                largeCircleValue7(mainString, userDataPersonal.birth)
                            ) +
                              getSum(
                                largeCircleValue6(mainString, userDataPersonal.birth) +
                                  largeCircleValue3(mainString, userDataPersonal.birth)
                              )
                          )
                        )
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="own_power_code">
        <h3>Ключ к расширению</h3>
        <div className="own_power_code-items">
          <div className="own_power_code-item">
            <h4 className="purpose_h4">Зона комфорта</h4>
            <div className="content circle_solo">
              <div className="circle">
                {largeCircleValueCenter(mainString, userDataPersonal.birth)}
              </div>
            </div>
          </div>
          <div className="own_power_code-item">
            <h4 className="purpose_h4">Сила Рода</h4>
            <div className="content circle_solo">
              <div className="circle">
                {getSum(
                  largeCircleValue2(mainString, userDataPersonal.birth) +
                    largeCircleValue3(mainString, userDataPersonal.birth) +
                    largeCircleValue6(mainString, userDataPersonal.birth) +
                    largeCircleValue7(mainString, userDataPersonal.birth)
                )}
              </div>
            </div>
          </div>
          <div className="own_power_code-item">
            <h4 className="purpose_h4">Личный потенциал</h4>
            <div className="content circle_solo">
              <div className="circle">
                {getSum(
                  getSum(
                    largeCircleValue2(mainString, userDataPersonal.birth) +
                      largeCircleValue3(mainString, userDataPersonal.birth) +
                      largeCircleValue6(mainString, userDataPersonal.birth) +
                      largeCircleValue7(mainString, userDataPersonal.birth)
                  ) + largeCircleValueCenter(mainString, userDataPersonal.birth)
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="map-of-year">
        <h3>Карта года</h3>
        <div className="map-of-year_items">
          <div className="content circle_solo">
            <div className="circle">{thirdCircle}</div>
          </div>
          <div className="content circle_solo">
            <div className="circle">{secondCircle}</div>
          </div>
          <div className="content circle_solo">
            <div className="circle">{firstCircle}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
