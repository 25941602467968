import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { CompatibilityMatrixForm } from '../../Forms/CompatibilityMatrixForm';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { MatrixCompatibility } from '../../MatrixCompatibility/MatrixCompatibility';
import './CompatibilityMatrixPage.scss';
import {
  getSum,
  largeCircleValue1,
  largeCircleValue2,
  largeCircleValue3,
  largeCircleValue4,
  largeCircleValue5,
  largeCircleValue6,
  largeCircleValue7,
  largeCircleValue8,
  largeCircleValueCenter,
  mediumCircleValue1,
  mediumCircleValue2,
  mediumCircleValue3,
  mediumCircleValue4,
  mediumCircleValue5,
  mediumCircleValue6,
  mediumCircleValue7,
  mediumCircleValue8,
  smallCircleValue1,
  smallCircleValue10,
  smallCircleValue11,
  smallCircleValue12,
  smallCircleValue13,
  smallCircleValue14,
  smallCircleValue2,
  smallCircleValue3,
  smallCircleValue4,
  smallCircleValue5,
  smallCircleValue6,
  smallCircleValue7,
  smallCircleValue8,
  smallCircleValue9,
} from '../../../functions/calculator';
import { MatrixCompatibilityResult } from '../../MatrixCompatibilityResult/MatrixCompatibilityResult';
import { AppointmentCompatibility } from '../../AppointmentCompatibility/AppointmentCompatibility';
import { storeSlice } from '../../../store/reducers/StoreSlice';
import { useNavigate } from 'react-router-dom';

interface IYearsTableProps {
  getEnergyValue: () => { [key: string]: number };
}

const CompatibilityMatrixPage = () => {
  const { mainString1, mainString2, usersDataCompatibility } = useAppSelector(
    (state) => state.storeReducer
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getCirclesValue = (mainString: string[], birth: string) => {
    const circleLarge1 = largeCircleValue1(mainString, birth);
    const circleLarge4 = largeCircleValue4(mainString, birth);
    const circleLarge2 = largeCircleValue2(mainString, birth);
    const circleLarge5 = largeCircleValue5(mainString, birth);
    const circleLarge3 = largeCircleValue3(mainString, birth);
    const circleLarge8 = largeCircleValue8(mainString, birth);
    const circleLarge6 = largeCircleValue6(mainString, birth);
    const circleLarge7 = largeCircleValue7(mainString, birth);
    const circleLargeCenter = largeCircleValueCenter(mainString, birth);

    const mediumCircle1 = mediumCircleValue1(mainString, birth);
    const mediumCircle2 = mediumCircleValue2(mainString, birth);
    const mediumCircle3 = mediumCircleValue3(mainString, birth);
    const mediumCircle4 = mediumCircleValue4(mainString, birth);
    const mediumCircle5 = mediumCircleValue5(mainString, birth);
    const mediumCircle6 = mediumCircleValue6(mainString, birth);
    const mediumCircle7 = mediumCircleValue7(mainString, birth);
    const mediumCircle8 = mediumCircleValue8(mainString, birth);

    const smallCircle1 = smallCircleValue1(mainString, birth);
    const smallCircle2 = smallCircleValue2(mainString, birth);
    const smallCircle3 = smallCircleValue3(mainString, birth);
    const smallCircle4 = smallCircleValue4(mainString, birth);
    const smallCircle5 = smallCircleValue5(mainString, birth);
    const smallCircle6 = smallCircleValue6(mainString, birth);
    const smallCircle7 = smallCircleValue7(mainString, birth);
    const smallCircle8 = smallCircleValue8(mainString, birth);
    const smallCircle9 = smallCircleValue9(mainString, birth);
    const smallCircle10 = smallCircleValue10(mainString, birth);
    const smallCircle11 = smallCircleValue11(mainString, birth);
    const smallCircle12 = smallCircleValue12(mainString, birth);
    const smallCircle13 = smallCircleValue13(mainString, birth);
    const smallCircle14 = smallCircleValue14(mainString, birth);

    return {
      circleLarge1,
      circleLarge4,
      circleLarge2,
      circleLarge5,
      circleLarge3,
      circleLarge8,
      circleLarge6,
      circleLarge7,
      circleLargeCenter,
      mediumCircle1,
      mediumCircle2,
      mediumCircle3,
      mediumCircle4,
      mediumCircle5,
      mediumCircle6,
      mediumCircle7,
      mediumCircle8,
      smallCircle1,
      smallCircle2,
      smallCircle3,
      smallCircle4,
      smallCircle5,
      smallCircle6,
      smallCircle7,
      smallCircle8,
      smallCircle9,
      smallCircle10,
      smallCircle11,
      smallCircle12,
      smallCircle13,
      smallCircle14,
    };
  };

  // const handleGeneratePdf = () => {
  //   const data = document.getElementById('matrixPDF')!;
  //   html2canvas(data).then((canvas) => {
  //     // Few necessary setting options
  //     const imgWidth = 297;
  //     const pageHeight = 295;
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const heightLeft = imgHeight;
  //
  //     const contentDataURL = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF('p', 'mm', [canvas.width, canvas.height + 120]);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight(); // A4 size page of PDF
  //     const position = 0;
  //     pdf.addImage(contentDataURL, 'PNG', 0, position, pdfWidth, pdfHeight);
  //     pdf.save('matrixPDF.pdf'); // Generated PDF
  //   });
  // };

  const handleGeneratePdf = () => {
    const data = document.getElementById('matrixPDF')!;
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      const imgWidth = 297;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf;
      if (window.innerWidth < 1100) {
        pdf = pdf = new jsPDF('p', 'mm', [canvas.width, canvas.height]);
      } else {
        pdf = new jsPDF('l', 'mm', 'a4');
      }
      const pdfHeight = pdf.internal.pageSize.getHeight(); // A4 size page of PDF
      let pdfWidth;
      const position = 0;
      if (window.innerWidth < 1100) {
        const pdfWidth = pdfHeight / 4.5;
        pdf.addImage(contentDataURL, 'PNG', 0, position, pdfWidth, pdfHeight);
      } else {
        const pdfWidth = pdf.internal.pageSize.getWidth();
        pdf.addImage(contentDataURL, 'PNG', 50, position, pdfWidth - 140, pdfHeight);
      }
      pdf.save('matrixPDF.pdf'); // Generated PDF
    });
  };

  return (
    <div className="matrixs_container" id="matrixPDF">
      {!(usersDataCompatibility.user1.birth && usersDataCompatibility.user2.birth) ? (
        <CompatibilityMatrixForm />
      ) : (
        <>
          <div className="table-wrapper">
            <a target="_blank" rel="noreferrer" href="https://t.me/risharik">
              <button className="btn btn_compatibility-matrix">ЗАКАЗАТЬ КОНСУЛЬТАЦИЮ</button>
            </a>
            <button className="button btn" style={{ width: 150 }} onClick={handleGeneratePdf}>
              Generate PDF
            </button>
          </div>
          <div className="matrixs-wrapper matrix-result">
            <MatrixCompatibilityResult
              mainString1={mainString1}
              mainString2={mainString2}
              userData1={usersDataCompatibility.user1}
              userData2={usersDataCompatibility.user2}
              getCirclesValue={getCirclesValue}
            />
            <AppointmentCompatibility
              mainString1={mainString1}
              mainString2={mainString2}
              userData1={usersDataCompatibility.user1}
              userData2={usersDataCompatibility.user2}
              getCirclesValue={getCirclesValue}
            />
          </div>
          <div className="matrixs-wrapper">
            <MatrixCompatibility
              mainString={mainString1}
              mainString2={mainString2}
              userData={usersDataCompatibility.user1}
              getCirclesValue={getCirclesValue}
            />
            <MatrixCompatibility
              mainString={mainString2}
              mainString2={mainString1}
              userData={usersDataCompatibility.user2}
              getCirclesValue={getCirclesValue}
            />
          </div>
          <button
            className="button btn"
            style={{ width: 150 }}
            onClick={() => {
              dispatch(storeSlice.actions.setClearState());
            }}
          >
            Ввести другую дату
          </button>
        </>
      )}
    </div>
  );
};

export default CompatibilityMatrixPage;
