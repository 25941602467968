import React from 'react';
import '../MatrixCompatibility/MarixMain.scss';
import Heart from '../../assets/heart.png';
import Dollar from '../../assets/dollar.png';

import { useAppSelector } from '../../hooks/redux';
import { IUserData, IUsersDataCompatibility } from '../../types';
import { getSum } from '../../functions/calculator';

interface IMatrixCompatibilityProps {
  mainString1: string[];
  mainString2: string[];
  userData1: IUserData;
  userData2: IUserData;
  getCirclesValue: (mainString: string[], birth: string) => { [key: string]: number };
}

export const MatrixCompatibilityResult = ({
  mainString1,
  mainString2,
  userData1,
  userData2,
  getCirclesValue,
}: IMatrixCompatibilityProps) => {
  const { userDataPersonal } = useAppSelector((state) => state.storeReducer);

  return (
    <div className="matrix_wrapper matrix-compatibility">
      <h3 className="h3_life-map">СОВМЕСТИМОСТЬ</h3>
      <div className="circle circle_large" id="large-center">
        <div className="circle circle_large_inside" id="large-center_inside">
          {getSum(
            getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
              getCirclesValue(mainString2, userData2.birth).circleLargeCenter
          )}
        </div>
      </div>
      <div className="circle circle_large" id="large-1">
        <div className="circle circle_large_inside" id="large-1_inside">
          {getSum(
            getCirclesValue(mainString1, userData1.birth).circleLarge1 +
              getCirclesValue(mainString2, userData2.birth).circleLarge1
          )}
        </div>
      </div>
      <div className="circle circle_large" id="large-2">
        {getSum(
          getCirclesValue(mainString1, userData1.birth).circleLarge2 +
            getCirclesValue(mainString2, userData2.birth).circleLarge2
        )}
      </div>
      <div className="circle circle_large" id="large-3">
        {getSum(
          getCirclesValue(mainString1, userData1.birth).circleLarge3 +
            getCirclesValue(mainString2, userData2.birth).circleLarge3
        )}
      </div>
      <div className="circle circle_large" id="large-4">
        <div className="circle circle_large_inside" id="large-4_inside">
          {getSum(
            getCirclesValue(mainString1, userData1.birth).circleLarge4 +
              getCirclesValue(mainString2, userData2.birth).circleLarge4
          )}
        </div>
      </div>
      <div className="circle circle_large" id="large-5">
        <div className="circle circle_large_inside" id="large-5_inside">
          {getSum(
            getCirclesValue(mainString1, userData1.birth).circleLarge5 +
              getCirclesValue(mainString2, userData2.birth).circleLarge5
          )}
        </div>
      </div>
      <div className="circle circle_large" id="large-6">
        {getSum(
          getCirclesValue(mainString1, userData1.birth).circleLarge6 +
            getCirclesValue(mainString2, userData2.birth).circleLarge6
        )}
      </div>
      <div className="circle circle_large" id="large-7">
        {getSum(
          getCirclesValue(mainString1, userData1.birth).circleLarge7 +
            getCirclesValue(mainString2, userData2.birth).circleLarge7
        )}
      </div>
      <div className="circle circle_large" id="large-8">
        <div className="circle circle_large_inside" id="large-8_inside">
          {getSum(
            getCirclesValue(mainString1, userData1.birth).circleLarge8 +
              getCirclesValue(mainString2, userData2.birth).circleLarge8
          )}
        </div>
      </div>
      {/*/!*<div className="circle circle_large" id="large_center-circle"></div>*!/*/}
      <div className="square" id="square-1"></div>
      <div className="square" id="square-2"></div>

      {/*<hr id="hr_top-left" />*/}
      {/*<hr id="hr_top-right" />*/}
      {/*<hr id="hr_top-left-2" />*/}
      {/*<hr id="hr_top-right-2" />*/}
      {/*<hr id="hr_bot-left-2" />*/}
      {/*<hr id="hr_bot-right-2" />*/}
      {/*<hr id="hr_bot-left" />*/}
      {/*<hr id="hr_bot-right" />*/}
      <hr id="hr_center-vertical" />
      <hr id="hr_center-horizontal" />

      <hr id="hr_rotate-1" />
      <hr id="hr_rotate-2" />
      {/*<img className="arrow" id="arrow-bot-red" src={RedArrow} alt="" />*/}
      {/*<img className="arrow" id="arrow-top-red" src={RedArrow} alt="" />*/}
      {/*<img className="arrow" id="arrow-bot-blue" src={BlueArrow} alt="" />*/}
      {/*<img className="arrow" id="arrow-top-blue" src={BlueArrow} alt="" />*/}
      <p className="line" id="men-line">
        Лінія чоловічого роду
      </p>
      <p className="line" id="women-line">
        Лінія жіночого роду
      </p>
      <p className="line" id="sky-line">
        Лінія Неба
      </p>
      <p className="line" id="earth-line">
        Лінія Землі
      </p>

      <img className="heart" id="heart" src={Heart} alt="" />
      <img className="dollar" id="dollar" src={Dollar} alt="" />

      <div className="circle circle_medium" id="medium-1">
        <div className="circle circle_medium_inside" id="medium-1_inside">
          {getSum(
            getSum(
              getSum(
                getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                  getCirclesValue(mainString2, userData2.birth).circleLargeCenter
              ) +
                getSum(
                  getCirclesValue(mainString1, userData1.birth).circleLarge1 +
                    getCirclesValue(mainString2, userData2.birth).circleLarge1
                )
            ) +
              getSum(
                getCirclesValue(mainString1, userData1.birth).circleLarge1 +
                  getCirclesValue(mainString2, userData2.birth).circleLarge1
              )
          )}
        </div>
      </div>
      {/*<div className="circle circle_medium" id="medium-2">*/}
      {/*  {getSum(*/}
      {/*    getCirclesValue(mainString1, userData1.birth).mediumCircle2 +*/}
      {/*      getCirclesValue(mainString2, userData2.birth).mediumCircle2*/}
      {/*  )}{' '}*/}
      {/*</div>*/}
      {/*<div className="circle circle_medium" id="medium-3">*/}
      {/*  {getSum(*/}
      {/*    getCirclesValue(mainString1, userData1.birth).mediumCircle3 +*/}
      {/*      getCirclesValue(mainString2, userData2.birth).mediumCircle3*/}
      {/*  )}{' '}*/}
      {/*</div>*/}
      <div className="circle circle_medium" id="medium-4">
        <div className="circle circle_medium_inside" id="medium-4_inside">
          {getSum(
            getSum(
              getSum(
                getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                  getCirclesValue(mainString2, userData2.birth).circleLargeCenter
              ) +
                getSum(
                  getCirclesValue(mainString1, userData1.birth).circleLarge4 +
                    getCirclesValue(mainString2, userData2.birth).circleLarge4
                )
            ) +
              getSum(
                getCirclesValue(mainString1, userData1.birth).circleLarge4 +
                  getCirclesValue(mainString2, userData2.birth).circleLarge4
              )
          )}{' '}
        </div>
      </div>
      <div className="circle circle_medium" id="medium-5">
        {getSum(
          getCirclesValue(mainString1, userData1.birth).mediumCircle5 +
            getCirclesValue(mainString2, userData2.birth).mediumCircle5
        )}{' '}
      </div>
      {/*<div className="circle circle_medium" id="medium-6">*/}
      {/*  {getSum(*/}
      {/*    getCirclesValue(mainString1, userData1.birth).mediumCircle6 +*/}
      {/*      getCirclesValue(mainString2, userData2.birth).mediumCircle6*/}
      {/*  )}{' '}*/}
      {/*</div>*/}
      {/*<div className="circle circle_medium" id="medium-7">*/}
      {/*  {getSum(*/}
      {/*    getCirclesValue(mainString1, userData1.birth).mediumCircle7 +*/}
      {/*      getCirclesValue(mainString2, userData2.birth).mediumCircle7*/}
      {/*  )}{' '}*/}
      {/*</div>*/}
      <div className="circle circle_medium" id="medium-8">
        {getSum(
          getSum(
            getSum(
              getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                getCirclesValue(mainString1, userData1.birth).circleLarge8
            ) +
              getSum(
                getCirclesValue(mainString2, userData2.birth).circleLargeCenter +
                  getCirclesValue(mainString2, userData2.birth).circleLarge8
              )
          ) +
            getSum(
              getCirclesValue(mainString1, userData1.birth).circleLarge8 +
                getCirclesValue(mainString2, userData2.birth).circleLarge8
            )
        )}
      </div>

      <div className="circle circle_small" id="small-1">
        <div className="circle circle_small_inside" id="small-1_inside">
          {getSum(
            getSum(
              getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                getCirclesValue(mainString2, userData2.birth).circleLargeCenter
            ) +
              getSum(
                getCirclesValue(mainString1, userData1.birth).circleLarge1 +
                  getCirclesValue(mainString2, userData2.birth).circleLarge1
              )
          )}
        </div>
      </div>
      {/*<div className="circle circle_small" id="small-2">*/}
      {/*  {getSum(*/}
      {/*    getCirclesValue(mainString1, userData1.birth).smallCircle2 +*/}
      {/*      getCirclesValue(mainString2, userData2.birth).smallCircle2*/}
      {/*  )}{' '}*/}
      {/*</div>*/}
      {/*<div className="circle circle_small" id="small-3">*/}
      {/*  {getSum(*/}
      {/*    getCirclesValue(mainString1, userData1.birth).smallCircle3 +*/}
      {/*      getCirclesValue(mainString2, userData2.birth).smallCircle3*/}
      {/*  )}{' '}*/}
      {/*</div>*/}
      {/*<div className="circle circle_small" id="small-4">*/}
      {/*  <div className="circle circle_small_inside" id="small-4_inside">*/}
      {/*    {getSum(*/}
      {/*      getCirclesValue(mainString1, userData1.birth).smallCircle4 +*/}
      {/*        getCirclesValue(mainString2, userData2.birth).smallCircle4*/}
      {/*    )}{' '}*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="circle circle_small" id="small-5">
        <div className="circle circle_small_inside" id="small-5_inside">
          {getSum(
            getSum(
              getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                getCirclesValue(mainString2, userData2.birth).circleLargeCenter
            ) +
              getSum(
                getCirclesValue(mainString1, userData1.birth).circleLarge4 +
                  getCirclesValue(mainString2, userData2.birth).circleLarge4
              )
          )}{' '}
        </div>
      </div>
      {/*<div className="circle circle_small" id="small-6">*/}
      {/*  <div className="circle circle_small_inside" id="small-6_inside">*/}
      {/*    {getSum(*/}
      {/*      getCirclesValue(mainString1, userData1.birth).smallCircle6 +*/}
      {/*        getCirclesValue(mainString2, userData2.birth).smallCircle6*/}
      {/*    )}{' '}*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="circle circle_small" id="small-7">
        <div className="circle circle_small_inside" id="small-7_inside">
          {getSum(
            getSum(
              getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                getCirclesValue(mainString2, userData2.birth).circleLargeCenter
            ) +
              getSum(
                getCirclesValue(mainString1, userData1.birth).circleLarge5 +
                  getCirclesValue(mainString2, userData2.birth).circleLarge5
              )
          )}{' '}
        </div>
      </div>
      {/*<div className="circle circle_small" id="small-8">*/}
      {/*  {getSum(*/}
      {/*    getCirclesValue(mainString1, userData1.birth).smallCircle8 +*/}
      {/*      getCirclesValue(mainString2, userData2.birth).smallCircle8*/}
      {/*  )}{' '}*/}
      {/*</div>*/}
      <div className="circle circle_small" id="small-9">
        {getSum(
          getSum(
            getSum(
              getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                getCirclesValue(mainString1, userData1.birth).circleLarge8
            ) +
              getSum(
                getCirclesValue(mainString2, userData2.birth).circleLargeCenter +
                  getCirclesValue(mainString2, userData2.birth).circleLarge8
              )
          ) +
            getSum(
              getSum(
                getSum(
                  getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                    getCirclesValue(mainString2, userData2.birth).circleLargeCenter
                ) +
                  getSum(
                    getCirclesValue(mainString1, userData1.birth).circleLarge5 +
                      getCirclesValue(mainString2, userData2.birth).circleLarge5
                  )
              ) +
                getSum(
                  getSum(
                    getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                      getCirclesValue(mainString1, userData1.birth).circleLarge8
                  ) +
                    getSum(
                      getCirclesValue(mainString2, userData2.birth).circleLargeCenter +
                        getCirclesValue(mainString2, userData2.birth).circleLarge8
                    )
                )
            )
        )}{' '}
      </div>
      {/*<div className="circle circle_small" id="small-10">*/}
      {/*  {getSum(*/}
      {/*    getCirclesValue(mainString1, userData1.birth).smallCircle10 +*/}
      {/*      getCirclesValue(mainString2, userData2.birth).smallCircle10*/}
      {/*  )}{' '}*/}
      {/*</div>*/}
      <div className="circle circle_small" id="small-11">
        {getSum(
          getSum(
            getSum(
              getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                getCirclesValue(mainString2, userData2.birth).circleLargeCenter
            ) +
              getSum(
                getCirclesValue(mainString1, userData1.birth).circleLarge5 +
                  getCirclesValue(mainString2, userData2.birth).circleLarge5
              )
          ) +
            getSum(
              getSum(
                getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                  getCirclesValue(mainString1, userData1.birth).circleLarge8
              ) +
                getSum(
                  getCirclesValue(mainString2, userData2.birth).circleLargeCenter +
                    getCirclesValue(mainString2, userData2.birth).circleLarge8
                )
            )
        )}{' '}
      </div>
      <div className="circle circle_small" id="small-12">
        {getSum(
          getSum(
            getSum(
              getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                getCirclesValue(mainString2, userData2.birth).circleLargeCenter
            ) +
              getSum(
                getCirclesValue(mainString1, userData1.birth).circleLarge5 +
                  getCirclesValue(mainString2, userData2.birth).circleLarge5
              )
          ) +
            getSum(
              getSum(
                getSum(
                  getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                    getCirclesValue(mainString2, userData2.birth).circleLargeCenter
                ) +
                  getSum(
                    getCirclesValue(mainString1, userData1.birth).circleLarge5 +
                      getCirclesValue(mainString2, userData2.birth).circleLarge5
                  )
              ) +
                getSum(
                  getSum(
                    getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                      getCirclesValue(mainString1, userData1.birth).circleLarge8
                  ) +
                    getSum(
                      getCirclesValue(mainString2, userData2.birth).circleLargeCenter +
                        getCirclesValue(mainString2, userData2.birth).circleLarge8
                    )
                )
            )
        )}{' '}
      </div>
      <div className="circle circle_small" id="small-13">
        <div className="circle circle_small_inside" id="small-13_inside">
          {getSum(
            getSum(
              getCirclesValue(mainString1, userData1.birth).circleLargeCenter +
                getCirclesValue(mainString1, userData1.birth).circleLarge8
            ) +
              getSum(
                getCirclesValue(mainString2, userData2.birth).circleLargeCenter +
                  getCirclesValue(mainString2, userData2.birth).circleLarge8
              )
          )}
        </div>
      </div>
    </div>
  );
};
